import { callPostApi } from '@/components/api-common';

export type OrgPaymentSettings =
  | {
      support_ach: boolean;
      support_credit_card: boolean;
      enabled: true;
    }
  | { enabled: false };

export type OrgAlliance = {
  id: string;
  name: string;
  orgs: {
    id: string;
    name: string;
    payment: OrgPaymentSettings;
  }[];
};

export type UserResponse = {
  user_id: string;
  org_id: string;
  enterprise_id: string | null;
  username: string;
  default_org_type: 'COMPANY' | 'INDIVIDUAL';
  is_admin: boolean;
  email: string;
  first_name: string;
  last_name: string;
  phone: string | null;
  stripe_customer_id: string;
  expired: boolean;
  default_org_subs_expire_time: number;
  default_org_purchase_type: string;
  expired_without_extension: boolean;
  org_alliances: OrgAlliance[];
};

export const fetchUser = async () => {
  return callPostApi(
    '/user/service/userAuth/user/v2/'
  ) as Promise<UserResponse>;
};
