import { FC, Fragment, useEffect, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import store from '@/store';
import type { RootState } from '@/store';
import { fetchUser, UserResponse } from '../../../api/user';
import { auth } from '../../../auth';
import tracker from '@/tracker';
import * as Sentry from '@sentry/react';

const disableIntercomAutoBootPath = ['/app/subscribe'];

type AuthenticatedProps = {
  children: ReactNode;
};

function adaptUserResponse(currentUser: UserResponse) {
  return {
    org_type: currentUser.default_org_type,
    isAdmin: currentUser.is_admin,
    ...currentUser,
    id: currentUser.user_id,
    username: currentUser.username,
  };
}

export function onUserAuthenticated(currentUser: UserResponse) {
  if (
    process.env.ENABLE_PROFITWELL == 'true' &&
    currentUser.stripe_customer_id
  ) {
    window.profitwell?.('start', { user_id: currentUser.stripe_customer_id });
  }
  tracker.onLogin(
    {
      email: currentUser.email,
      userId: currentUser.user_id,
      username: currentUser.username,
      orgId: currentUser.org_id,
      enterpriseId: currentUser.enterprise_id,
      orgType: currentUser.default_org_type,
    },
    {
      intercom: {
        autoBoot: !disableIntercomAutoBootPath.includes(
          window.location.pathname
        ),
      },
    }
  );

  Sentry.setUser({
    email: currentUser.email,
    id: currentUser.user_id,
    username: currentUser.username,
  });
  store.dispatch({
    type: 'LOGIN',
    user: adaptUserResponse(currentUser),
  });
}

export const refreshCurrentUser = async () => {
  const currentUser = await fetchUser();
  store.dispatch({
    type: 'LOGIN',
    user: adaptUserResponse(currentUser),
  });
};

const Authenticated: FC<AuthenticatedProps> = ({ children }) => {
  const user = useSelector((state: RootState) => state.user);
  useEffect(() => {
    if (!auth.isAuthenticated) {
      return;
    }
    if (user) return;
    const fetchCurrentUser = async () => {
      const currentUser = await fetchUser();
      onUserAuthenticated(currentUser);
    };
    fetchCurrentUser();
  }, [user]);

  if (auth.isAuthenticated) {
    return <Fragment>{children}</Fragment>;
  }

  return null;
};

export default Authenticated;
