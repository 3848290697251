import { JsonObject } from 'type-fest';
import mixpanel from 'mixpanel-browser';
import { IntercomWhitelistedEvents } from './intercom';
import { isInApp } from './utils/platform';

type Payload = JsonObject;
type Profile = {
  email: string;
  userId: string;
  username: string;
  orgId: string;
  enterpriseId: string | null;
  orgType: 'COMPANY' | 'INDIVIDUAL';
};
type Settings = {
  intercom?: {
    autoBoot?: boolean;
  };
};
type Handler = {
  logEvent(event: string, payload?: Payload): void;
  onLogin(profile: Profile, settings?: Settings): void;
  onLogout(): void;
  whiteListedEvents?: Set<string>;
  profile?: Profile | null;
};

const tracker = {
  handlers: [] as Handler[],
  register(handler: Handler) {
    this.handlers.push(handler);
  },
  logEvent(event: string, payload?: Payload) {
    this.handlers.forEach(handler => {
      const trackAll = !handler.whiteListedEvents;
      const trackWhitelist =
        handler.whiteListedEvents && handler.whiteListedEvents.has(event);
      if (trackAll || trackWhitelist) {
        handler.logEvent(event, payload);
      }
    });
  },
  onLogin(profile: Profile, settings?: Settings) {
    this.handlers.forEach(handler => handler.onLogin(profile, settings));
  },
  onLogout() {
    this.handlers.forEach(handler => handler.onLogout());
  },
};

const mixpanelHandler: Handler = {
  logEvent(event: string, payload?: Payload) {
    const eventPayload = payload || {};
    mixpanel.track(event, eventPayload);
  },
  onLogin(profile: Profile) {
    mixpanel.reset();
    this.profile = profile;
    mixpanel.identify(profile.username);
    mixpanel.people.set({ email: profile.email });
    if (profile.orgType != 'COMPANY') {
      return;
    }
    mixpanel.set_group('org_id', profile.orgId);
    if (profile.enterpriseId) {
      mixpanel.set_group('enterprise_id', profile.enterpriseId);
    }
  },
  onLogout() {
    mixpanel.reset();
    this.profile = null;
  },
};
const intercomHandler: Handler = {
  logEvent(event: string, payload?: Payload) {
    window.Intercom('trackEvent', event, payload);
  },
  onLogin(profile: Profile, settings) {
    if (!settings?.intercom?.autoBoot) return;
    window.Intercom('boot', {
      app_id: process.env.INTERCOM_APP_ID,
      user_id: profile.username,
      email: profile.email,
      vertical_padding: 120,
      horizontal_padding: 60,
      hide_default_launcher: isInApp(),
    });
  },
  onLogout() {
    window.Intercom('shutdown');
  },
  whiteListedEvents: IntercomWhitelistedEvents,
};

tracker.register(mixpanelHandler);
tracker.register(intercomHandler);

export default tracker;
